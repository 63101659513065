<template>
  <div class="columns is-multiline">
    <div class="column is-half">
      <div class="field">
        <label class="label">Τιμή Αγοράς *</label>
        <div class="control">
          <input
            v-validate="'required|excluded:0'"
            :disabled="isProduct"
            :value="newProduct.buy_price"
            class="input"
            type="number"
            min="0"
            step="0.01"
            name="buy_price"
            @input="updatePrice"
          />
          <p v-show="errors.has('buy_price')" class="help is-danger">
            Εισάγετε τιμή αγοράς
          </p>
        </div>
      </div>
    </div>

    <div class="column is-half">
      <div class="field">
        <label class="label">Π.Λ. Τιμή Πώλησης *</label>
        <div class="control">
          <input
            v-validate="'required|excluded:0'"
            :disabled="isProduct"
            :value="newProduct.sell_price"
            class="input"
            type="number"
            min="0"
            step="0.01"
            name="sell_price"
            @input="updatePrice"
          />
          <p v-show="errors.has('sell_price')" class="help is-danger">
            Εισάγετε τιμή πώλησης
          </p>
        </div>
      </div>
    </div>

    <div class="column is-half">
      <div class="field">
        <label class="label">Ποσοστό Έκπτωσης *</label>
        <div class="control">
          <input
            v-validate="'required|min_value:0'"
            :value="newProduct.discount"
            :disabled="hasPeriodicDiscount"
            class="input"
            type="number"
            min="0"
            step="0.01"
            name="discount"
            @input="updatePrice"
          />
          <p v-show="errors.has('discount')" class="help is-danger">
            Εισάγετε ποσοστό έκπτωσης
          </p>
        </div>
      </div>
    </div>

    <div class="column is-half">
      <div class="field">
        <label class="label">Ποσοστό Περιοδικής Έκπτωσης *</label>
        <div class="control">
          <input
            v-validate="'required|excluded:0'"
            :value="periodicDiscount"
            disabled
            class="input"
            type="text"
            name="periodic_discount"
            @input="updatePrice"
          />
          <p v-show="errors.has('periodic_discount')" class="help is-danger">
            Εισάγετε ποσοστό περιοδικής έκπτωσης
          </p>
        </div>
      </div>
    </div>

    <slot />

    <div class="column is-half">
      <div class="field">
        <template v-if="hasPeriodicDiscount">
          <label class="label">Τελική Τιμή με Περιοδική Έκπτωση *</label>
          <div class="control">
            <input
              :value="product.final_price_with_periodic_discount"
              disabled
              class="input"
              type="number"
              min="0"
              step="0.01"
              name="final_price_with_periodic_discount"
            />
          </div>
        </template>
        <template v-else>
          <label class="label">Τελική Τιμή *</label>
          <div class="control">
            <input
              v-validate="'required|excluded:0'"
              :value="newProduct.final_price"
              :disabled="isSet || isBundle || hasPeriodicDiscount"
              class="input"
              type="number"
              min="0"
              step="0.01"
              name="final_price"
              @input="updatePrice"
            />
            <p v-show="errors.has('final_price')" class="help is-danger">
              Εισάγετε τελική τιμή
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _pick from 'lodash/pick';
import _round from 'lodash/round';

export default {
  inject: ['$validator'],
  props: {
    product: Object,
    type: String,
  },

  data: () => ({
    newProduct: {},
  }),

  computed: {
    isProduct() {
      return this.type === 'product';
    },
    isSet() {
      return this.type === 'set';
    },
    isGroup() {
      return this.type === 'group';
    },
    isBundle() {
      return this.type === 'bundle';
    },
    hasPeriodicDiscount() {
      return this.newProduct.periodic_discount?.active;
    },
    periodicDiscount() {
      return this.hasPeriodicDiscount
        ? `${this.newProduct.periodic_discount.title} (${this.newProduct.periodic_discount.discount}%)`
        : 'Χωρίς περιοδική έκπτωση';
    },
  },

  watch: {
    product: {
      handler(newVal) {
        this.setProduct(newVal);
      },
      deep: true,
    },
  },

  created() {
    this.setProduct(this.product);
  },

  methods: {
    setProduct(product) {
      this.newProduct = _pick(product, [
        'buy_price',
        'sell_price',
        'discount',
        'final_price',
        'periodic_discount',
      ]);
    },
    updatePrice(e) {
      /* eslint-disable camelcase, prefer-const */
      let { name, value } = e.target;
      value = Number(value);
      let price = {
        [name]: value,
      };

      if (name === 'sell_price') {
        price = {
          ...price,
          final_price: _round(
            value - (this.newProduct.discount * value) / 100,
            2,
          ),
        };
      }

      /* eslint-disable no-restricted-globals */
      if (name === 'discount') {
        const { sell_price } = this.newProduct;
        const newFinalPrice = _round(
          sell_price - (value * sell_price) / 100,
          2,
        );

        price = {
          ...price,
          final_price: isNaN(newFinalPrice) ? 0 : newFinalPrice,
        };
      }

      if (name === 'final_price') {
        const { sell_price } = this.newProduct;
        const newDiscount = Math.abs(
          _round(((value - sell_price) / sell_price) * 100, 2),
        );

        price = {
          ...price,
          discount: isNaN(newDiscount) ? 0 : newDiscount,
        };
      }

      this.$emit('updatePrice', price);
    },
  },
};
</script>
