<template>
  <form class="has-sticky-submit" @submit.prevent="submit">
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          {{ titleDescription }}
        </div>
        <div class="card-header-icon">
          <div class="field has-addons is-marginless">
            <div class="control">
              <router-link
                :to="{ name: 'marketing.promos.list' }"
                class="button"
              >
                <span class="icon is-small"><i class="fa fa-list-ul"/></span
                ><span>Λίστα</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label class="label">Τίτλος *</label>
              <div class="control">
                <input
                  v-validate="'required'"
                  v-model.trim="form.title"
                  class="input"
                  type="text"
                  name="title"
                />
                <p v-show="errors.has('title')" class="help is-danger">
                  Εισάγετε τίτλο
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Slug *</label>
              <div class="control">
                <input
                  v-validate="'regex:^[a-z0-9]+(?:-[a-z0-9]+)*$'"
                  v-model.trim="form.slug"
                  class="input"
                  type="text"
                  name="slug"
                />
                <p v-show="errors.has('slug')" class="help is-danger">
                  Εισάγετε έγκυρο slug
                </p>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Ενεργό *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="form.active" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">Crawlable *</label>
              <div class="control">
                <div class="select select is-fullwidth">
                  <select v-model.number="form.crawlable" name="active">
                    <option :value="false">Όχι</option>
                    <option :value="true">Ναί</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label class="label">E-shop URL</label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input
                    ref="path"
                    :value="path"
                    name="path"
                    class="input"
                    type="text"
                    readonly
                  />
                </div>
                <div class="control">
                  <button
                    v-tooltip="'Copy path'"
                    class="button"
                    @click.prevent="handleCopy"
                  >
                    <span class="icon"><i class="fa fa-clone"/></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EditPhotos
      :model="promo"
      :photos="photos"
      :is-loading="isLoadingPhotos"
      @updatePhotos="updatePhotos"
    />

    <ToggleableCard :is-open="true" title="Σχετικά Προϊόντα">
      <div class="card-content">
        <div class="field">
          <label class="label">Προϊόντα</label>
          <div class="control">
            <ProductsSearch
              :products="form.products"
              @selectProduct="selectProduct"
              @removeProduct="removeProduct"
            />
          </div>
        </div>
      </div>
    </ToggleableCard>

    <StickyFormFooter>
      <template v-slot:left>
        <FormSubmit :is-saving="isSaving" @reset="reset" />
      </template>
      <template v-slot:right>
        <a v-tooltip="'Προβολή στο e-shop'" :href="path" target="_blank">
          {{ promo.title }} <i class="fa fa-arrow-right" />
        </a>
      </template>
    </StickyFormFooter>
  </form>
</template>

<script>
import slug from 'slug';
import { pick, clone } from 'lodash';
import AddItem from '@/views/components/AddItem';
import request from '@/utils/request';
import ToggleableCard from '@/views/components/ToggleableCard';
import ProductsSearch from '@/views/components/ProductsSearch';
import EditPhotos from '@/views/components/EditPhotos';
import StickyFormFooter from '@/views/components/StickyFormFooter';

export default {
  components: {
    ToggleableCard,
    ProductsSearch,
    EditPhotos,
    StickyFormFooter,
  },

  extends: AddItem,

  data() {
    return {
      promo: {},
      form: {},
      photos: [],
      isLoadingPhotos: false,
    };
  },

  computed: {
    path() {
      return `https://carespot.gr/promos/${this.promo.slug}`;
    },

    titleDescription() {
      const { promo } = this;
      /* eslint-disable */ if (promo.active === true) {
        return 'Ενεργό Marketing Page';
      } else if (promo.active === false) {
        return 'Μη Ενεργό Marketing Page';
      } else {
        return 'Marketing Page';
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const { data } = await request.get(`/promos/${to.params.uuid}`);

      next(vm => vm.setData(data));
    } catch (err) {
      next(vm => vm.$router.push({ name: 'error' }));
    }
  },

  watch: {
    'form.title': {
      handler(newVal, oldVal) {
        // When title is already set and a super admin changes it
        // then we need to update slugs, urls and titles in various places
        if (oldVal !== undefined && newVal !== oldVal) {
          this.newPromo.slug = slug(newVal.toLowerCase());
        }
      },
    },
  },

  methods: {
    setData(data) {
      this.promo = {
        ...data.promo,
        photoable_type: 'App\\Promo',
      };

      this.form = pick(this.promo, [
        'title',
        'slug',
        'active',
        'crawlable',
        'products',
      ]);
      this.photos = clone(this.promo.photos);
    },

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;

        const { data } = await request.put(`/promos/${this.promo.uuid}`, {
          ...this.form,
          products: this.form.products.map(({ id }) => id),
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το Marketing Page αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.form = pick(this.promo, [
        'title',
        'slug',
        'active',
        'crawlable',
        'products',
      ]);
      this.photos = clone(this.promo.photos);

      this.$validator.reset();
    },

    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;

        const { data } = await request.get(
          `/promos/${this.$route.params.uuid}/photos`,
        );

        if (data.error) {
          throw Error('Server-side validation failed');
        }
        this.photos = clone(data.promo.photos);

        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },

    selectProduct(product) {
      this.form = {
        ...this.form,
        products: [...(this.form.products || []), product],
      };
    },

    removeProduct(product) {
      this.form = {
        ...this.form,
        products: this.form.products.filter(({ id }) => id !== product.id),
      };
    },

    handleCopy() {
      const copyText = this.$refs.path;
      copyText.select();
      document.execCommand('copy');

      this.$notify({
        type: 'success',
        title: 'Επιτυχία',
        text: 'Το path αντιγράφτηκε στο clipboard',
      });
    },
  },
};
</script>
