<template>
  <LoadingContainer :is-loading="isLoading">
    <form novalidate @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Προιόντα
            </div>
          </div>
          <div class="card-header-icon">
            <div class="field has-addons is-marginless">
              <div class="control">
                <router-link :to="{ name: 'products.list' }" class="button">
                  <span class="icon is-small"><i class="fa fa-list-ul"/></span
                  ><span>Λίστα</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Ονομασία *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="general.title"
                    class="input"
                    type="text"
                    name="title"
                  />
                  <p v-show="errors.has('title')" class="help is-danger">
                    Εισάγετε ονομασία
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Περιγραφή *</label>
                <div class="control">
                  <textarea
                    v-validate="'required'"
                    v-model.trim="general.description"
                    class="textarea"
                    name="description"
                  />
                  <p v-show="errors.has('description')" class="help is-danger">
                    Εισάγετε περιγραφή
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Περιεχόμενο *</label>
                <div class="control">
                  <VueEditor
                    v-validate="'required'"
                    id="editor-1"
                    v-model="general.content"
                    :editor-toolbar="toolbarOptions"
                    data-vv-value-path="value"
                    data-vv-name="content"
                    @input="changeContent('content', $event)"
                  />
                </div>
                <p v-show="errors.has('content')" class="help is-danger">
                  Εισάγετε περιεχόμενο
                </p>
              </div>
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Συστατικά</label>
                <div class="control">
                  <VueEditor
                    id="editor-2"
                    :editor-toolbar="toolbarOptions"
                    v-model="general.ingredients"
                    @input="changeContent('ingredients', $event)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="columns is-multiline">
            <div class="column is-half">
              <div class="field">
                <label class="label">Barcode *</label>
                <div class="control">
                  <input
                    v-validate="'required'"
                    v-model.trim="general.barcode"
                    class="input"
                    type="text"
                    name="barcode"
                  />
                  <p v-show="errors.has('barcode')" class="help is-danger">
                    Εισάγετε barcode
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Διαθεσιμότητα *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select
                      v-model.trim.number="general.availability_id"
                      name="availability_id"
                    >
                      <option
                        v-for="item in availabilities"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-half">
              <div class="field">
                <label class="label">Βάρος *</label>
                <div class="control">
                  <input
                    v-validate="'required|excluded:0'"
                    v-model.trim.number="general.weight"
                    class="input"
                    type="number"
                    name="weight"
                    min="0"
                    step="0.01"
                  />
                  <p v-show="errors.has('weight')" class="help is-danger">
                    Εισάγετε βάρος
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToggleableCard :is-open="true" title="Κατηγορίες">
        <div class="card-content">
          <EditCategories
            :categories="general.categories"
            @updateCategories="updateCategories"
          />
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Τιμολογιακή Πολιτική">
        <div class="card-content">
          <EditPrice :product="general" @updatePrice="updatePrice">
            <div class="column is-half">
              <div class="field">
                <label class="label">ΦΠΑ *</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model.trim.number="general.tax_id" name="tax_id">
                      <option
                        v-for="item in taxes"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.percentage }} %
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </EditPrice>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Brand & Collection">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <label class="label">Brand *</label>
              <brands-search
                :brand="general.brand"
                @selectBrand="selectBrand"
                @removeBrand="removeBrand"
              />
              <p v-show="errors.has('brand')" class="help is-danger">
                Επιλέξτε brand
              </p>
            </div>

            <div v-if="general.brand_id" class="column is-full">
              <label class="label">Collection</label>
              <collections-brands-search
                :brand="general.brand"
                :collection="general.collection"
                @selectCollection="selectCollection"
                @removeCollection="removeCollection"
              />
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Συμπτώματα & Tags">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <label class="label">Συμπτώματα</label>
              <symptoms-search
                :symptoms="general.symptoms"
                @selectSymptom="selectSymptom"
                @removeSymptom="removeSymptom"
              />
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Tags</label>
                <div class="control">
                  <multiselect
                    v-model="general.tags"
                    :options="tags"
                    :multiple="true"
                    name="tags"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε tags"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Promos">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <div class="control">
                  <multiselect
                    v-model="general.promos"
                    :options="promos"
                    :multiple="true"
                    name="promos"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε Marketing Pages"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Φίλτρα">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <filters-search
                :filters="general.filters"
                @selectFilter="selectFilter"
                @removeFilter="removeFilter"
              />
            </div>
          </div>
        </div>
      </ToggleableCard>

      <div class="card">
        <div class="card-footer">
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </div>
      </div>
    </form>
  </LoadingContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import { VueEditor } from 'vue2-editor';
import request from '@/utils/request';
import AddItem from '@/views/components/AddItem';
import ToggleableCard from '@/views/components/ToggleableCard';
import LoadingContainer from '@/views/components/LoadingContainer';
import toolbarOptions from '@/constants/toolbarOptions';
import EditCategories from '../components/EditCategories';
import EditPrice from '../components/EditPrice';
import BrandsSearch from '../components/BrandsSearch';
import CollectionsBrandsSearch from '../components/CollectionsBrandsSearch';
import SymptomsSearch from '../components/SymptomsSearch';
import FiltersSearch from '../components/FiltersSearch';

export default {
  components: {
    VueEditor,
    Multiselect,
    LoadingContainer,
    ToggleableCard,
    EditCategories,
    EditPrice,
    BrandsSearch,
    CollectionsBrandsSearch,
    SymptomsSearch,
    FiltersSearch,
  },
  extends: AddItem,
  data() {
    return {
      general: {
        title: '',
        slug: '',
        barcode: '',
        description: '',
        content: '',
        ingredients: '',
        buy_price: 0,
        sell_price: 0,
        discount: 0,
        final_discount: 0,
        final_price: 0,
        final_price_with_periodic_discount: 0,
        weight: 0,
        tax_id: null,
        availability_id: 1,
        tags: [],
        promos: [],
        brand_id: null,
        brand: null,
        collection_id: null,
        collection: null,
        symptoms: [],
        categories: [],
      },
      isSaving: false,
      toolbarOptions,
    };
  },
  computed: {
    ...mapGetters({
      availabilities: 'getAvailabilities',
      taxes: 'taxes/list/getTaxes',
      tags: 'tags/all/getTags',
      promos: 'promos/all/getPromos',
      categoriesReverseTree: 'categories/list/getCategoriesReverseTree',
      isLoading: 'categories/list/getIsLoading',
    }),
  },
  watch: {
    taxes(newVal) {
      this.general.tax_id = newVal.find(tax => tax.default === 1).id;
    },
  },
  async created() {
    try {
      await Promise.all([
        this.getTags(),
        this.getPromos(),
        this.getTaxes(),
        this.getCategories(),
      ]);
    } catch (err) {
      this.$router.push('/error').catch(e => console.log(e));
    }
  },
  methods: {
    ...mapActions({
      getTags: 'tags/all/getTags',
      getPromos: 'promos/all/getPromos',
      getTaxes: 'taxes/list/getTaxes',
      getCategories: 'categories/list/getCategories',
    }),
    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        const { data } = await request.post('/products', {
          ...this.general,
          tags: this.general.tags.map(({ id }) => id),
          symptoms: this.general.symptoms.map(({ id }) => id),
          promos: this.general.promos.map(({ id }) => id),
          categories: this.general.categories.map(
            ({ uuid }) => this.categoriesReverseTree[uuid].id,
          ),
        });
        this.isSaving = false;

        if (data.error) {
          throw Error('Server-side validation failed');
        }

        this.$router
          .push({ name: 'products.list' })
          .catch(err => console.log(err));
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το προιόν αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = {
        title: '',
        slug: '',
        barcode: '',
        description: '',
        content: '',
        ingredients: '',
        buy_price: 0,
        sell_price: 0,
        discount: 0,
        final_discount: 0,
        final_price: 0,
        final_price_with_periodic_discount: 0,
        weight: 0,
        tax_id: this.defaultTax,
        availability_id: 1,
        tags: [],
        promos: [],
        brand_id: null,
        brand: null,
        collection_id: null,
        collection: null,
        symptoms: [],
        categories: [],
      };

      this.$validator.reset();
    },
    changeContent(el, content) {
      this.general[el] = content === '<p><br></p>' ? '' : content;
    },
    selectBrand(brand) {
      this.general = {
        ...this.general,
        brand_id: brand.id,
        brand,
        collection_id: null,
        collection: null,
      };
    },
    removeBrand() {
      this.general = {
        ...this.general,
        brand_id: null,
        brand: null,
        collection_id: null,
        collection: null,
      };
    },
    selectCollection(collection) {
      this.general = {
        ...this.general,
        collection_id: collection.id,
        collection,
      };
    },
    removeCollection() {
      this.general = {
        ...this.general,
        collection_id: null,
        collection: null,
      };
    },
    selectSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: [...this.general.symptoms, symptom],
      };
    },
    removeSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: this.general.symptoms.filter(({ id }) => id !== symptom.id),
      };
    },
    selectFilter(filter) {
      this.general = {
        ...this.general,
        filters: [...this.general.filters, filter],
      };
    },
    removeFilter(filter) {
      this.general = {
        ...this.general,
        filters: this.general.filters.filter(({ id }) => id !== filter.id),
      };
    },
    updateCategories({ categories }) {
      this.general = {
        ...this.general,
        categories,
      };
    },
    updatePrice(price) {
      this.general = {
        ...this.general,
        ...price,
      };
    },
  },
};
</script>
