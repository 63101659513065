<template>
  <div>
    <div class="tabs hidden-print">
      <ul v-sticky sticky-offset="offset">
        <RouterLink
          :to="{ name: 'marketing.promos.list.active' }"
          tag="li"
          active-class="is-active"
        >
          <a>Ενεργά Marketing Pages</a>
        </RouterLink>
        <RouterLink
          :to="{ name: 'marketing.promos.list.inactive' }"
          tag="li"
          active-class="is-active"
        >
          <a>Μη Ενεργά Marketing Pages</a>
        </RouterLink>
        <RouterLink
          :to="{ name: 'marketing.promos.list.all' }"
          tag="li"
          active-class="is-active"
        >
          <a>Όλα τα Marketing Pages</a>
        </RouterLink>
      </ul>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data: () => ({
    offset: { top: 45 },
  }),
};
</script>
