<template>
  <tr>
    <td>
      <ToggleButton
        :sync="true"
        :value="isChecked"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        :disabled="!isEditable"
        @change="onChange"
      />
    </td>
    <td>
      <span
        v-tooltip="{
          content: isEditable
            ? 'Μπορεί να αλλάξει απο το CMS'
            : 'Μπορεί να αλλάξει μόνο απο το ERP',
        }"
      >
        {{ item.title }}
      </span>
      <div v-if="hasVoucherId">
        <small class="tag">{{ order.voucher_id }}</small>
      </div>
      <div v-if="hasInvoiceId">
        <small class="tag">{{ order.invoice_id }}</small>
      </div>
      <div v-if="isChecked">
        <div>
          <small v-if="item.pivot.user">{{ item.pivot.user.name }}</small>
          <small v-else-if="!isEditable && !isNew">ERP</small>
          <small v-else>ESHOP</small>
        </div>
        <div>
          <small>{{ item.pivot.updated_at | timestamp }}</small>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'Status',
  props: {
    index: Number,
    item: Object,
    order: Object,
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.item.checked;
    },
    isEditable() {
      return this.item.editable;
    },
    isNew() {
      return this.item.slug === 'new';
    },
    hasVoucherId() {
      return this.item.slug === 'voucher' && this.order.voucher_id;
    },
    hasInvoiceId() {
      return this.item.slug === 'invoice' && this.order.invoice_id;
    },
  },
  methods: {
    onChange() {
      this.$emit('changeStatus', {
        status: this.item,
        isChecked: !this.isChecked,
      });
    },
  },
};
</script>
