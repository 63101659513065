var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tabs hidden-print"},[_c('ul',{directives:[{name:"sticky",rawName:"v-sticky"}],attrs:{"sticky-offset":"offset"}},[_c('RouterLink',{attrs:{"to":{ name: 'marketing.coupons.list.active' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Ενεργά Κουπόνια ")])])]}}])}),_c('RouterLink',{attrs:{"to":{ name: 'marketing.coupons.list.inactive' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Μη Ενεργά Κουπόνια ")])])]}}])}),_c('RouterLink',{attrs:{"to":{ name: 'marketing.coupons.list.all' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Όλα τα Κουπόνια ")])])]}}])}),_c('RouterLink',{attrs:{"to":{ name: 'marketing.coupons.list.deleted' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active is-active'],attrs:{"role":"link"}},[_c('a',{attrs:{"href":href},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)},"click":navigate}},[_vm._v(" Ακυρωμένα Κουπόνια ")])])]}}])})],1)]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }