<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Κατάσταση</div>
    </div>
    <div class="card-content">
      <LoadingContainer
        :is-loading="isLoading"
        :is-opaque="true"
        :is-vertically-aligned="true"
      >
        <table class="table is-fullwidth">
          <tbody>
            <status
              v-for="(item, index) in statuses"
              :key="index"
              :item="item"
              :index="index"
              :order="order"
              :is-paid="isPaid"
              :is-deleted="isDeleted"
              @changeStatus="changeStatus"
            />
          </tbody>
        </table>
      </LoadingContainer>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingContainer from '@/views/components/LoadingContainer';
import Status from './components/Status';

export default {
  name: 'OrderStatuses',
  components: {
    LoadingContainer,
    Status,
  },
  props: {
    order: Object,
    statuses: Array,
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    ...mapActions(['addStatus', 'removeStatus']),
    async changeStatus({ status, isChecked }) {
      try {
        this.isLoading = true;
        if (isChecked) {
          await this.addStatus({ statusId: status.id });
        } else {
          await this.removeStatus({ statusId: status.id });
        }
        this.isLoading = false;
        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Το status της παραγγελίας άλλαξε επιτυχώς!!',
        });
      } catch (err) {
        this.isLoading = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
